import React from 'react';
import logo from '../../assets/logos/logo_small.svg';
import styles from './loading.module.scss';

const Loading = () => (
  <div className={styles.wrapper}>
    <img src={logo} alt="Kandu Logo" className={styles.logo} />
  </div>
);

export default Loading;
