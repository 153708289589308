import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import styles from './pop-up.module.scss';

const PopUp = ({
  showModal,
  setShowModal,
  title,
  size,
  children,
  setRenderRegister = () => {},
  renderRegister,
}) => {
  const sizeStyles = {
    small: styles.smallSize,
    medium: styles.mediumSize,
    large: styles.largeSize,
  };

  const ref = useRef();

  const handleOverlayClick = (e) => {
    if (ref.current === e.target) {
      setShowModal(false);
      if (renderRegister) setRenderRegister(true);
    }
  };

  // Event listeners
  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
        if (renderRegister) setRenderRegister(true);
      }
    },
    [showModal, setShowModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  // Render
  if (!showModal) return null;

  return (
    <div className={styles.overlay} onClick={handleOverlayClick} ref={ref}>
      <div className={`${styles.wrapper} ${sizeStyles[size] || sizeStyles.medium}`}>
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>
          <CloseIcon
            className={styles.icon}
            onClick={() => {
              setShowModal(false);
              if (renderRegister) setRenderRegister(true);
            }}
          />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

PopUp.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  title: PropTypes.string,
  setRenderRegister: PropTypes.func,
  renderRegister: PropTypes.bool,
};

export default PopUp;
