import React from 'react';
import styles from './real.module.scss';

const Real = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={`${styles.section} ${styles.left}`}>
          <h2 className={styles.content}>Real food</h2>
        </div>
        <div className={`${styles.section} ${styles.right}`}>
          <h2 className={styles.content}>Real ingredients</h2>
        </div>
      </div>
    </div>
  );
};

export default Real;
