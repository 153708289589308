import create from 'zustand';

const useStore = create((set) => ({
  lang: window.localStorage.getItem('language') || 'es',
  customer: null,
  cart: null,
  isCustomerLoading: true,
  isCartOpen: false,
  setLang: (language) => {
    window.localStorage.setItem('language', language);
    set(() => ({ lang: language }));
  },
  setCustomer: (customer) => set(() => ({ customer: customer })),
  setCart: (cart) => set(() => ({ cart: cart })),
  setIsCustomerLoading: (bool) => set(() => ({ isCustomerLoading: bool })),
  setIsCartOpen: (isCartOpen) => set(() => ({ isCartOpen: !isCartOpen })),
}));

export default useStore;
