import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../router/routeList.js';
import styles from './not-found.module.scss';
import useStore from 'store/store';
import content from './content.js';
import CTA from '../CallToAction';
import Navbar from '../Navbar';

const NotFound = () => {
  const lang = useStore((state) => state.lang);
  return (
    <>
      <Navbar isCheckout={true} shouldInherit={true} />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h1>404</h1>
            <p>{content.title[lang]}</p>
            <Link to={routes.home}>
              <CTA text={content.cta[lang]} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
