import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import useForm from 'hooks/useForm';
import usernameIcon from '../../../../assets/logos/username.svg';
import calendarIcon from '../../../../assets/logos/calendar.svg';
import emailIcon from '../../../../assets/logos/email.svg';
import passwordIcon from '../../../../assets/logos/password.svg';

import useStore from 'store/store';
import content from '../../content.js';

import routes from 'router/routeList';
import toast from 'react-hot-toast';

import CallToAction from 'components/CallToAction';
import DateInput from 'components/DateInput';
import useAuth from 'hooks/useAuth';
import { fromDMYToISO } from 'utils/dates';
import styles from './registerForm.module.scss';

const RegisterForm = ({ setShowModal, setRenderRegister, shouldRedirect }) => {
  const lang = useStore((state) => state.lang);
  const { registerUser, isLoading, error } = useAuth();
  const { formData, handleInputChange, isDisabled } = useForm({
    name: '',
    email: '',
    password: '',
    birthDate: '',
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    registerUser(
      {
        ...formData,
        birthDate: fromDMYToISO(formData.birthDate), // Back-end expects ISO date
      },
      () => {
        const toastMessage =
          lang === 'es'
            ? 'Usuario registrado correctamente. Bienvenido a la familia Kandú!'
            : 'User successfully registered. Welcome to the Kandú family!';
        toast.success(toastMessage, {
          duration: 5000,
          fontSize: '16px',
        });
        setShowModal(false);
      },
      shouldRedirect,
    );
  };

  return (
    <div>
      <div>
        <form id="registration-form" className={styles.form}>
          <div className={styles.inputContainer}>
            <div className={styles.svgContainer}>
              <img src={usernameIcon} />
            </div>
            <input
              type="text"
              name="name"
              placeholder={content.register.placeholder1[lang]}
              autoComplete="on"
              value={formData.name}
              onChange={handleInputChange}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.svgContainer}>
              <img src={emailIcon} />
            </div>
            <input
              type="email"
              name="email"
              placeholder={content.register.placeholder2[lang]}
              autoComplete="on"
              value={formData.email}
              onChange={handleInputChange}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.svgContainer}>
              <img src={passwordIcon} />
            </div>
            <input
              name="password"
              value={formData.password}
              type="password"
              autoComplete="new-password"
              placeholder={content.register.placeholder3[lang]}
              onChange={handleInputChange}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.svgContainer}>
              <img src={calendarIcon} />
            </div>
            <DateInput
              type="text"
              name="birthDate"
              inputMode="numeric"
              maxLength="10"
              pattern="[0-9]*"
              autoComplete="off"
              placeholder={content.register.placeholder4[lang]}
              value={formData.birthDate}
              onChange={handleInputChange}
              formatType="birthDate"
            ></DateInput>
          </div>
        </form>
        <div className={styles.ctaContainer}>
          {error && <div className={styles.error}>{error}</div>}
          <CallToAction
            form="registration-form"
            text={content.register.cta[lang]}
            disabled={isDisabled}
            onClick={handleFormSubmit}
            isLoading={isLoading}
          />
        </div>
        <div className={styles.linksContainer}>
          <span>
            {content.register.span1[lang]}
            {'  '}
            <Link className={styles.underline} to={routes.terms}>
              {content.register.link1[lang]}
            </Link>{' '}
          </span>
          <span>
            {content.register.span2[lang]}
            <a
              onClick={() => {
                setRenderRegister(false);
              }}
              className={styles.link}
            >
              {'  '}
              {content.register.link2[lang]}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

RegisterForm.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setRenderRegister: PropTypes.func.isRequired,
  shouldRedirect: PropTypes.bool,
};

export default RegisterForm;
