export default {
  title: {
    en: 'Meet Our Bars',
    es: 'Conoce nuestras barritas',
  },
  body: {
    en: `A snack for any time of the day, ideal for between meals or on
    those days when you dont have time for breakfast or lunch.
   Its high percentage of healthy fats and protein allows you to
   satisfy your hunger with a nutritious product.`,
    es: `Snack para cualquier hora del día, ideal para usarlo entre comidas
    o en esos días en los que no alcanza el tiempo para desayunar o
    almorzar. Su alto porcentaje de grasas buenas y proteína permite
    saciar el hambre con un producto realmente nutritivo.`,
  },
  cta: {
    en: 'Buy Now',
    es: 'Comprar',
  },
};
