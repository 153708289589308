import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import englishIcon from '../../../assets/logos/usa.svg';
import spanishIcon from '../../../assets/logos/spain.svg';
import instagramIcon from '../../../assets/logos/instagram.svg';
import facebookIcon from '../../../assets/logos/facebook.svg';

import useStore from 'store/store';

import navLinks from './hamburgerLinks.js';
import styles from './hamburger-menu.module.scss';

const HamburgerMenu = ({ isOpen, setIsOpen }) => {
  const lang = useStore((state) => state.lang);
  const setLang = useStore((state) => state.setLang);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={`${styles.wrapper} ${isOpen ? styles.open : ''}`}>
        <div className={styles.container}>
          <div className={styles.header}>
            <CloseIcon className={styles.closeIcon} onClick={() => setIsOpen(false)} />
          </div>
          <div className={styles.content}>
            <nav className={styles.linksContainer}>
              {navLinks.map((section, i) => (
                <div key={`section_${i}`}>
                  <h5 className={styles.title}>{section.title[lang]}</h5>
                  <ul>
                    {section.links.map((link, j) => (
                      <li
                        className={styles.linkContainer}
                        key={`f_link_${j}`}
                        onClick={handleLinkClick}
                      >
                        <Link to={link.to} className={styles.link}>
                          {link.text[lang]}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
              <div>
                <h5 className={styles.title}>
                  {lang === 'es' ? 'Redes' : 'Social Media'}
                </h5>
                <ul className={styles.socialLinks}>
                  <li className={styles.socialLinkContainer}>
                    <a href="https://www.instagram.com/kandu.ec" className={styles.link}>
                      <img
                        src={instagramIcon}
                        alt="instagram logo"
                        className={styles.icon}
                      />
                    </a>
                  </li>
                  <li className={styles.socialLinkContainer}>
                    <a href="https://www.facebook.com/kandu.ec/" className={styles.link}>
                      <img
                        src={facebookIcon}
                        alt="facebook logo"
                        className={styles.icon}
                      />
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className={styles.languageContainer}
                onClick={() => {
                  if (lang === 'es') {
                    setLang('en');
                  } else {
                    setLang('es');
                  }
                }}
              >
                <img
                  src={lang === 'es' ? spanishIcon : englishIcon}
                  alt="language icon"
                  className={styles.languageIcon}
                />
                <p>{lang === 'es' ? 'Español' : 'English'}</p>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

HamburgerMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default HamburgerMenu;
