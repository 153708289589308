export default {
  header: {
    en: 'When to eat Kandú?',
    es: '¿Cuándo comer una Kandú?',
  },
  work: {
    title: {
      en: 'Work',
      es: 'Trabajo',
    },
    description: {
      en: `Boost your concentration
      at study or work`,
      es: `Mientras trabajas para tener energía y concentración`,
    },
  },
  adventure: {
    title: {
      en: 'Adventure',
      es: 'Aventura',
    },
    description: {
      en: `Bring it on your excursions
      and adventures`,
      es: `Como snack en todas tus excursiones y aventuras`,
    },
  },
  workout: {
    title: {
      en: 'Workout',
      es: 'Entrenamiento',
    },
    description: {
      en: `Before or after
      your workout`,
      es: `Antes o después de cualquier ejercicio`,
    },
  },
  snack: {
    title: {
      en: 'Snack',
      es: 'Snack',
    },
    description: {
      en: `As a topping for your
      bowls or smoothies`,
      es: `El topping perfecto para tus bowls o batidos`,
    },
  },
};
