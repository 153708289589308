export default [
  // {
  //   name: 'Doménica Vanoni',
  //   occupation: 'Health Coach',
  //   image: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/testimonials/dome_vanoni.JPEG',
  //   text: `I couldn't have asked for more than this.
  //   Needless to say I am extremely satisfied
  //   with the results. No matter where you
  //   go, Kandú is the coolest, most happening
  //   thing around!`,
  // },
  {
    name: 'Cristina Game',
    occupation: 'Emprendedora',
    image: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/testimonials/cristi_game_2.jpg',
    text: `Sinceramente soy fan #1 de Kandú. Aparte de ser el snack perfecto y riquísimo, me han acompañado y apoyado en diferentes deportes, actividades, cafés, y hasta proyectos de labor social.

    En mi caso, al practicar deportes tan conectados a la naturaleza, donde te limitas a snacks fáciles y cómodos de transportar, Kandú me salva.
    Ingredientes naturales, energía y comodidad = el aliado perfecto de cualquier deportista.

    Agradecida siempre con esta marca increíble :)`,
  },
  {
    name: 'David Palacios',
    occupation: 'Deportista',
    image:
      'https://kandu.nyc3.cdn.digitaloceanspaces.com/testimonials/david_palacios.jpg',
    text: 'Además de su sabor y valor nutritivo me encanta que las Kandú son incondicionales. Me acompañan en mis entrenamientos más fuertes así como en las salidas largas a la montaña o entre semana como snack en el trabajo. Tener una a la mano siempre es una buena idea.',
  },
];
