export default {
  about: '/nosotros',
  checkout: '/pago',
  checkoutConfirmation: '/confirmacion',
  contact: '/contacto',
  home: '/',
  logout: '/salir',
  products: '/productos/:slug',
  profile: '/perfil',
  recoverPassword: '/contraseña',
  terms: '/terminos',
  ordersInfo: '/info',
  storeLocator: '/tiendas',
  cart: '/carro',
};
