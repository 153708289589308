const months = {
  0: 'Ene',
  1: 'Feb',
  2: 'Mar',
  3: 'Abr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Ago',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dic',
};

export const formatVideoDuration = (duration) => {
  /*
    00:36:49 => 36:49
    01:00:00 => 1:36:49
    00:06:49 => 6:49
    00:00:49 => 0:49
  */

  const times = duration.split(':');
  let h = times[0];
  let m = times[1];
  let s = times[2];

  if (h === '00') {
    h = '';
  } else if (h[0] === '0') {
    h = `${h[1]}:`;
  } else {
    h = h + ':';
  }

  if (m === '00' && h === '') {
    m = '0:';
  } else if (m[0] === '0' && h === '') {
    m = `${m[1]}:`;
  } else {
    m = m + ':';
  }

  return `${h}${m}${s}`;
};

export const convertISOToShortDate = (date) => {
  /*
    Input: string ~ represents timestamp => 1997-12-26T05:00:00.000Z
    Output: string ~ represents date => 26/12/1997
  */

  const newDate = new Date(date);

  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString(); // month is 0-indexed
  const day = newDate.getDate().toString();

  const newDay = day.length === 1 ? '0'.concat(day) : day;
  const newMonth = month.length === 1 ? '0'.concat(month) : month;

  return `${newDay}/${newMonth}/${year}`;
};

export const convertUnixEpochToDDMMYYYY = (timestamp) => {
  /*

    Converts UNIX timestamp (seconds) to date in format DDMMYYYY

    Input: integer ~ timestamp in seconds
    Output: string ~ representing date
 */

  const date = new Date(timestamp * 1000);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString(); // month is 0-indexed
  const day = date.getDate().toString();

  const newDay = day.length === 1 ? '0'.concat(day) : day;
  const newMonth = month.length === 1 ? '0'.concat(month) : month;

  return `${newDay}/${newMonth}/${year}`;
};

export const convertTimestampToDate = (timestamp) => {
  /*

    Converts UNIX timestamp (seconds) to date string

    Input: integer ~ timestamp in seconds
    Output: string ~ representing date
 */

  if (typeof timestamp !== 'number')
    throw new Error(
      `Timestamp must be a number, but got ${timestamp} of type ${typeof timestamp}`,
    );

  const milliseconds = timestamp * 1000;
  const date = new Date(milliseconds);

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return `${months[month]} ${day}, ${year}`;
};

export const convertTimestampToAccessDate = (date) => {
  date = date * 1000;

  const monthNames = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
  ];

  const newDate = new Date(date);

  if (typeof date !== 'number')
    throw new Error(`Input must be a number, but got ${date} of type ${typeof date}`);

  const year = newDate.getFullYear();
  const month = newDate.getMonth();
  const day = newDate.getDate().toString();

  const newDay = day.length === 1 ? '0'.concat(day) : day;

  return `${newDay} de ${monthNames[month]} de ${year}`;
};

export const getToday = () => {
  return new Date(Date.now());
};

export const isValidDateChar = (char) => {
  return /^[0-9/]*$/.test(char);
};

export const isValidCardChar = (char) => {
  return /^[0-9 ]*$/.test(char);
};

export const addDateSeparator = (newDate, currentDate) => {
  /*
    Add a date separator to string. 30082021 => 30/08/2021
    Input: string ~ 30
    Output: string ~ 30/
  */
  const separator = '/';
  const length = newDate.length;

  if (length === 2 || length === 5) {
    // Remove separator on backspace
    const currentLastChar = currentDate[currentDate.length - 1];
    if (currentLastChar === separator) {
      return currentDate.slice(0, currentDate.length - 2);
    } else {
      // Add separator
      return (newDate += separator);
    }
  } else {
    return newDate;
  }
};

export const addCreditCardSeparator = (newDate, currentDate) => {
  /*
    Add a date separator to string. 30082021 => 30/08/2021
    Input: string ~ 30
    Output: string ~ 30/
  */

  const separator = '/';
  const length = newDate.length;

  if (length === 2) {
    // Remove separator on backspace
    const currentLastChar = currentDate[currentDate.length - 1];
    if (currentLastChar === separator) {
      return currentDate.slice(0, currentDate.length - 2);
    } else {
      // Add separator
      return (newDate += separator);
    }
  } else {
    return newDate;
  }
};

export const spaceCardNumber = (newDate, currentDate) => {
  const separator = ' ';
  const length = newDate.length;

  if (length === 4 || length === 9 || length === 14) {
    // Remove separator on backspace
    const currentLastChar = currentDate[currentDate.length - 1];
    if (currentLastChar === separator) {
      return currentDate.slice(0, currentDate.length - 2);
    } else {
      // Add separator
      return (newDate += separator);
    }
  } else {
    return newDate;
  }
};

export const fromDMYToISO = (date) => {
  /*
    This function transforms a date with format "dd/mm/yyyy" to ISO 8601
    "dd/mm/yyyy" => "yyyy-mm-dd"

    Input: string
    Output: string
  */

  const [day, month, year] = date.split('/');
  return `${year}-${month}-${day}`;
};

export const convertToReadableDate = (dateStr) => {
  /*
    Transforms date to readable form => Jun 8, 2021

    Input: String ~ represents date
    Output: String
 */

  const date = new Date(dateStr);

  // Year
  const year = date.getFullYear();

  // Month
  const monthNum = date.getMonth();
  const month = months[monthNum];

  // Day
  const day = date.getDate();

  return `${month} ${day}, ${year}`;
};
