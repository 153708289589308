import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import ErrorBoundary from './components/ErrorBoundary';
import Router from './router';

import useUser from 'hooks/useUser';
import useCart from 'hooks/useCart';

const AppContent = () => {
  const { initializeUser } = useUser();
  const { initializeCart } = useCart();

  useEffect(() => {
    initializeUser();
    initializeCart();
  }, []);

  return (
    <>
      <Router />
      <Toaster />
    </>
  );
};

const AppWrapper = () => (
  <div id="app-wrapper">
    <ErrorBoundary>
      <AppContent />
    </ErrorBoundary>
  </div>
);

export default AppWrapper;
