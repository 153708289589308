import callApi from './helpers/callApi';

export const getOrder = ({ id }) => {
  const options = {
    method: 'GET',
    url: `/orders/${id}`,
  };

  return callApi(options);
};

export const createOrder = (data) => {
  const options = {
    method: 'POST',
    url: '/orders',
    data,
  };

  return callApi(options);
};

export const getShippingOptions = () => {
  const options = {
    method: 'GET',
    url: '/shipping-options',
  };

  return callApi(options);
};

export const sendOrderConfirmation = (orderId, data) => {
  const options = {
    method: 'POST',
    url: `/orders/${orderId}/confirmation`,
    data,
  };

  return callApi(options);
};
