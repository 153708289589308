import React from 'react';
import PropTypes from 'prop-types';
import links from 'links';
import styles from '../../home.module.scss';
import content from './content.js';
import { Link } from 'react-router-dom';

const HeroSection = ({ lang }) => {
  return (
    <div className={styles.heroSectionWrapper}>
      <div className={styles.heroSectionContent}>
        <div className={styles.heroInfo}>
          <h1 className={styles.homePageTitle}>{content.header[lang]}</h1>
          <Link to={`/productos/${links.chocoBananaSlug}`} className={styles.link}>
            <button className={styles.button}>{content.cta[lang]}</button>
          </Link>
        </div>
        <div className={styles.heroPhoto}>
          <div className={styles.heroPeanuts}>
            <img
              src="https://kandu.nyc3.cdn.digitaloceanspaces.com/static/peanuts_hero.png"
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <div className={styles.heroBar}>
              <img src={links.chocoBananaPhoto} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HeroSection.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default HeroSection;
