import callApi from './helpers/callApi';

export const getCustomer = (data) => {
  const options = {
    method: 'GET',
    url: '/customers/me',
    data,
  };

  return callApi(options);
};

export const updateCustomer = (data) => {
  const options = {
    method: 'PATCH',
    url: '/customers/me',
    data,
  };

  return callApi(options);
};

export const getAllCustomerOrders = (page) => {
  const options = {
    method: 'GET',
    url: `/customers/me/orders?page=${page}&limit=3`,
  };

  return callApi(options);
};
