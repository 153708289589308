import React from 'react';
import { Link } from 'react-router-dom';

import footerLinks from '../footerLinks';
import useStore from 'store/store';
import styles from '../footer.module.scss';

const SiteLinks = () => {
  const lang = useStore((state) => state.lang);
  return (
    <div className={styles.linksSectionContainer}>
      {footerLinks.map((section, i) => (
        <div className={styles.linksSection} key={`section_${i}`}>
          <h5 className={styles.title}>{section.title[lang]}</h5>
          <ul>
            {section.links.map((link, j) => (
              <li className={styles.linkContainer} key={`f_link_${j}`}>
                <Link to={link.to} className={styles.link}>
                  {link.text[lang]}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default SiteLinks;
