export default {
  title: {
    en: 'Page does not exist',
    es: 'Página no existe',
  },
  cta: {
    en: 'Go to main page',
    es: 'Ir a página principal',
  },
};
