import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RiMenu2Fill as HamburgerMenuIcon } from '@react-icons/all-files/ri/RiMenu2Fill';
import logo from '../../assets/logos/logo_word.svg';
import PropTypes from 'prop-types';
import AuthModal from 'components/AuthModal';
import englishIcon from '../../assets/logos/usa.svg';
import spanishIcon from '../../assets/logos/spain.svg';
import userIcon from '../../assets/logos/user.svg';
import cartIcon from '../../assets/logos/shoppingBag.svg';
import links from './info/links';
import routes from '../../router/routeList.js';
import styles from './navbar.module.scss';
import useStore from 'store/store';
import HamburgerMenu from './HamburgerMenu';

const Navbar = ({ shouldInherit, isCheckout }) => {
  const state = useStore((state) => state);
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const openModal = () => {
    setShowModal(!showModal);
  };

  const navLinks = links.publicLinks;

  const isCurrentPath = (route) => {
    return route === pathname;
  };

  return (
    <>
      <HamburgerMenu isOpen={showHamburgerMenu} setIsOpen={setShowHamburgerMenu} />
      <header className={`${styles.navbar} ${shouldInherit ? styles.inherit : ''}`}>
        <div className={styles.content}>
          <div className={`${styles.leftContainer} ${isCheckout ? styles.checkout : ''}`}>
            <nav className={styles.nav}>
              {navLinks.map((link, i) => {
                if (i === 0) {
                  return (
                    <div
                      key={`${i}_${link.to}`}
                      className={`${styles.link} ${
                        isCurrentPath(link.to) ? styles.currentLink : ''
                      }`}
                    >
                      <div className={styles.productsLink}>
                        <span>{link.name[state.lang]}</span>
                        <div className={styles.hoverLink}>
                          <Link className={styles.linkOption} to={link.protein}>
                            {state.lang === 'es' ? 'Barras de proteína' : 'Protein Bars'}
                          </Link>
                          <Link className={styles.linkOption} to={link.energy}>
                            {state.lang === 'es' ? 'Barras de energía' : 'Energy Bars'}
                          </Link>
                          <Link className={styles.linkOption} to={link.granola}>
                            {state.lang === 'es' ? 'Granolas' : 'Granolas'}
                          </Link>
                          <Link className={styles.linkOption} to={link.shirt}>
                            {state.lang === 'es' ? 'T-Shirts' : 'T-Shirts'}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <Link
                      to={link.to}
                      key={`${i}_${link.to}`}
                      className={`${styles.link} ${
                        isCurrentPath(link.to) ? styles.currentLink : ''
                      }`}
                    >
                      {link.name[state.lang]}
                    </Link>
                  );
                }
              })}
            </nav>
          </div>
          <div className={styles.logoWrapper}>
            <Link to={routes.home} className={styles.logoContainer}>
              <img
                src={logo}
                alt="Kandú Logo"
                className={`${styles.logo} ${shouldInherit ? styles.blackSvg : ''}`}
              />
            </Link>
          </div>
          {isCheckout ? null : (
            <HamburgerMenuIcon
              className={styles.hamburgerIcon}
              onClick={() => setShowHamburgerMenu(true)}
            />
          )}
          <div
            className={`${styles.buttonsWrapper} ${isCheckout ? styles.checkout : ''}`}
          >
            <div className={`${styles.buttonContainer} ${styles.languageButton} `}>
              {state.lang === 'es' ? (
                <img
                  className={styles.button}
                  src={englishIcon}
                  onClick={() => {
                    setTimeout(() => {
                      state.setLang('en');
                    }, 500);
                  }}
                />
              ) : (
                <img
                  className={styles.button}
                  src={spanishIcon}
                  onClick={() => {
                    setTimeout(() => {
                      state.setLang('es');
                    }, 500);
                  }}
                />
              )}
            </div>
            {state.customer ? (
              <Link to={routes.profile}>
                <div className={styles.buttonContainer}>
                  <img
                    src={userIcon}
                    className={`${styles.button} ${shouldInherit ? styles.blackSvg : ''}`}
                  />
                </div>
              </Link>
            ) : (
              <div onClick={() => openModal()} className={styles.buttonContainer}>
                <img
                  src={userIcon}
                  className={`${styles.button} ${shouldInherit ? styles.blackSvg : ''}`}
                />
              </div>
            )}
            <div
              onClick={() => {
                state.setIsCartOpen(state.isCartOpen);
              }}
              className={styles.cartIconContainer}
            >
              <img
                src={cartIcon}
                className={`${styles.button} ${shouldInherit ? styles.blackSvg : ''}`}
              />
              {state.cart && state.cart.productCount > 0 && (
                <div className={styles.cartBubble}>{state.cart.productCount}</div>
              )}
            </div>
          </div>
        </div>
      </header>
      <AuthModal
        showModal={showModal}
        setShowModal={setShowModal}
        showRegister={true}
        shouldRedirect={false}
      />
    </>
  );
};

Navbar.propTypes = {
  shouldInherit: PropTypes.bool,
  isCheckout: PropTypes.bool,
};

export default Navbar;
