import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Honeybadger } from '@honeybadger-io/react';
import { updateCustomer } from 'api/customer';
import { sendOrderConfirmation } from 'api/checkout';
import { getCurrentCart } from 'api/cart';
import useStore from 'store/store';
import routes from 'router/routeList';

export default () => {
  const history = useHistory();
  const store = useStore((state) => state);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Helpers
  const handleStart = () => {
    setIsLoading(true);
    setError(null);
  };

  const redirectToCheckout = async (errorHandler) => {
    try {
      handleStart();
      const cart = await getCurrentCart();
      store.setIsCartOpen(store.isCartOpen);
      setIsLoading(false);
      history.push({
        pathname: routes.checkout,
        state: {
          cart,
        },
      });
    } catch (error) {
      errorHandler(error.response?.data);
      setIsLoading(false);
    }
  };

  const submitOrder = async (orderId, transactionData, formData) => {
    try {
      history.push({
        pathname: routes.checkoutConfirmation,
        state: {
          orderEmail: formData.email,
        },
      });

      store.setCart(null);
      await sendOrderConfirmation(orderId, transactionData);
    } catch (error) {
      Honeybadger.setContext({
        user_email: formData.email,
        orderId,
        transactionId: transactionData.transactionId,
      });
      Honeybadger.notify(error.response?.data);
    }
  };

  const saveUserData = async (orderData) => {
    try {
      await updateCustomer({
        address: orderData.shippingAddress,
      });
    } catch (error) {
      Honeybadger.setContext({
        user_email: orderData.customer.email,
      });
      Honeybadger.notify(error.response?.data);
    }
  };

  return {
    submitOrder,
    redirectToCheckout,
    saveUserData,
    isLoading,
    error,
    setIsLoading,
    setError,
  };
};
