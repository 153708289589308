import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import content from './content.js';
import links from 'links';
import styles from './nutritionTable.module.scss';

const NutritionTable = ({ lang }) => {
  const tableData = [
    { name: content.serving[lang], value: '35-46 g' },
    { name: content.energy[lang], value: '164-233 kcal' },
    { name: content.protein[lang], value: '8-13 g' },
    { name: content.ingredients[lang], value: '< 7' },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.informationSection}>
          <h1 className={styles.title} style={{ whiteSpace: 'pre-line' }}>
            {content.title[lang]}
          </h1>
          <p>{content.body[lang]}</p>
          <Link to={`/productos/${links.peanutButterSlug}`}>
            <p className={styles.action}>{content.cta[lang]}</p>
          </Link>
        </div>
        <div className={styles.tableSection}>
          <div className={styles.table}>
            <div className={styles.tableContent}>
              {tableData.map((field, i) => {
                return (
                  <div key={`${i}_${field.name}`} className={styles.tableEntry}>
                    <span>{field.name}</span>
                    <span>{field.value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NutritionTable.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default NutritionTable;
