import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getCustomer } from 'api/customer';
import { getCurrentCart } from 'api/cart';
import { register, login, requestPasswordChange, changePassword, logout } from 'api/auth';
import useStore from 'store/store';

import routes from '../router/routeList';
import passwordRoutes from '../views/Password/subRoutes.js';

export default () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const setCustomer = useStore((state) => state.setCustomer);
  const setCart = useStore((state) => state.setCart);

  // Helpers
  const handleStart = () => {
    setIsLoading(true);
    setError(null);
  };

  const handleError = (error) => {
    setIsLoading(false);
    setError(error.response?.data);
  };

  const setStoreCustomer = async () => {
    const data = await getCustomer();
    setCustomer(data);
  };

  const logoutUser = async () => {
    try {
      await logout();
      setCustomer(null);
      setCart(null);
    } catch (error) {
      handleError(error);
    } finally {
      history.push(routes.home);
    }
  };

  const registerUser = async (data, cb = () => {}, shouldRedirect = true) => {
    handleStart();
    try {
      await register(data);
      const customer = await getCustomer();
      await setCustomer(customer);
      cb();
      if (shouldRedirect) {
        history.push({
          pathname: routes.home,
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const loginUser = async (data, cb = () => {}, shouldRedirect = true) => {
    try {
      handleStart();
      await login(data);
      await setStoreCustomer();
      cb();
      const cart = await getCurrentCart();
      setCart(cart);
      if (shouldRedirect) {
        history.push(routes.home);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const sendChangePasswordEmail = async (data) => {
    try {
      handleStart();
      await requestPasswordChange(data);
      history.push({
        pathname: routes.recoverPassword.concat(passwordRoutes.confirmEmail),
        state: data,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const changeUserPassword = async (data) => {
    handleStart();

    try {
      await changePassword(data);
      history.push(routes.recoverPassword.concat(passwordRoutes.confirmationPage));
    } catch (error) {
      handleError(error);
    }
  };

  return {
    sendChangePasswordEmail,
    changeUserPassword,
    registerUser,
    loginUser,
    logoutUser,
    isLoading,
    error,
  };
};
