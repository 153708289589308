import React from 'react';
import PropTypes from 'prop-types';
import styles from './cta.module.scss';

const CallToAction = ({
  disabled,
  form,
  isLoading,
  onClick,
  text,
  color = '',
  addBorder,
  isCheckout,
}) => {
  const ctaColor = {
    black: styles.black,
    '': '',
  };
  return (
    <button
      form={form}
      className={`${styles.button} ${ctaColor[color]} ${
        addBorder ? styles.addBorder : ''
      } ${isCheckout ? styles.checkout : ''}`}
      disabled={disabled}
      onClick={onClick}
    >
      {isLoading ? <div className={styles.loader}></div> : text}
    </button>
  );
};

CallToAction.propTypes = {
  text: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  addBorder: PropTypes.bool,
  isCheckout: PropTypes.bool,
};

export default CallToAction;
