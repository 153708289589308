import React from 'react';
import { Link } from 'react-router-dom';
import emailIcon from '../../../../assets/logos/email.svg';
import passwordIcon from '../../../../assets/logos/password.svg';
import PropTypes from 'prop-types';
import useForm from 'hooks/useForm';
import useAuth from 'hooks/useAuth';
import useStore from 'store/store';
import content from '../../content.js';
import CallToAction from 'components/CallToAction';
import routes from 'router/routeList';
import styles from '../RegisterForm/registerForm.module.scss';

const LoginForm = ({ setShowModal, setRenderRegister, shouldRedirect }) => {
  const lang = useStore((state) => state.lang);
  const { loginUser, isLoading, error } = useAuth();
  const { formData, handleInputChange, isDisabled } = useForm({
    email: '',
    password: '',
  });

  const handleFormSubmit = (e) => {
    e.preventDefault();
    loginUser(
      formData,
      () => {
        setShowModal(false);
      },
      shouldRedirect,
    );
  };

  return (
    <div>
      <div>
        <form id="login-form" className={styles.form}>
          <div className={styles.inputContainer}>
            <div className={styles.svgContainer}>
              <img src={emailIcon} />
            </div>
            <input
              type="email"
              name="email"
              autoComplete="on"
              placeholder={content.login.placeholder1[lang]}
              value={formData.email}
              onChange={handleInputChange}
            ></input>
          </div>
          <div className={styles.inputContainer}>
            <div className={styles.svgContainer}>
              <img src={passwordIcon} />
            </div>
            <input
              type="password"
              name="password"
              autoComplete="new-password"
              placeholder={content.login.placeholder2[lang]}
              value={formData.password}
              onChange={handleInputChange}
            ></input>
          </div>
        </form>
        <div className={styles.ctaContainer}>
          {error && <div className={styles.error}>{error}</div>}
          <CallToAction
            form="login-form"
            text={content.login.cta[lang]}
            isLoading={isLoading}
            disabled={isDisabled}
            onClick={handleFormSubmit}
          />
        </div>
        <div className={styles.linksContainer}>
          <Link to={routes.recoverPassword}>
            <span onClick={() => setShowModal(false)} className={styles.underline}>
              {content.login.span1[lang]}
            </span>
          </Link>
          <span>
            {content.login.span2[lang]}
            <a
              onClick={() => {
                setRenderRegister(true);
              }}
              className={styles.link}
            >
              {'  '}
              {content.login.link[lang]}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

LoginForm.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  setRenderRegister: PropTypes.func,
  shouldRedirect: PropTypes.bool,
};

export default LoginForm;
