import React, { Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import PrivateRoute from './components/PrivateRoute';
import RedirectTo404 from './components/RedirectTo404';

import Cart from 'components/Cart';

// Critical Components
import Home from '../views/Home';

// Non-Critical Components
const Checkout = lazy(() => import('../views/Checkout'));
const Contact = lazy(() => import('../views/Contact'));
const ProductPage = lazy(() => import('../views/ProductPage'));
const Profile = lazy(() => import('../views/Profile'));
const Terms = lazy(() => import('../views/Terms'));
const About = lazy(() => import('../views/About'));
const OrdersAndShipping = lazy(() => import('../views/OrdersAndShipping'));
const StoreLocator = lazy(() => import('../views/StoreLocator'));
const Password = lazy(() => import('../views/Password'));
const Logout = lazy(() => import('components/Logout'));
const CheckoutConfirmation = lazy(() =>
  import('../views/Checkout/components/CheckoutConfirmation'),
);

import routes from './routeList';

const AppRouter = () => (
  <Suspense fallback={null}>
    <ScrollToTop>
      <Cart />
      <Switch>
        <Route exact path={routes.home} component={Home} />
        <Route exact path={routes.products} component={ProductPage} />
        <Route exact path={routes.logout} component={Logout} />
        <Route exact path={routes.terms} component={Terms} />
        <Route exact path={routes.about} component={About} />
        <Route exact path={routes.checkout} component={Checkout} />
        <Route
          exact
          path={routes.checkoutConfirmation}
          component={CheckoutConfirmation}
        />
        <Route exact path={routes.contact} component={Contact} />
        <Route exact path={routes.ordersInfo} component={OrdersAndShipping} />
        <Route exact path={routes.storeLocator} component={StoreLocator} />
        <Route path={routes.recoverPassword} component={Password} />
        <PrivateRoute path={routes.profile} component={Profile} />
        <RedirectTo404 />
      </Switch>
    </ScrollToTop>
  </Suspense>
);

export default AppRouter;
