import React from 'react';
import Footer from 'components/Footer';
import HeroSection from './components/HeroSection';
import WhenToEat from './components/WhenToEat';
import BarAnatomy from './components/BarAnatomy';
import Hashtags from './components/Hashtags';
import Real from './components/Real';
import ProductsDisplay from './components/ProductsDisplay';
import MeetOurBars from './components/MeetOurBars';
import NutritionTable from './components/NutritionTable';
import UserReviews from './components/UserReviews';
import useStore from 'store/store';
import styles from './home.module.scss';
import Navbar from 'components/Navbar';

const Home = () => {
  const lang = useStore((state) => state.lang);
  return (
    <>
      <Navbar />
      <div className={styles.wrapper}>
        <HeroSection lang={lang} />
        <Hashtags text="YOU KANDÚ" />
        <WhenToEat lang={lang} />
        <Real />
        <BarAnatomy lang={lang} />
        <Hashtags text="#realfood" />
        <ProductsDisplay />
        <MeetOurBars lang={lang} />
        <NutritionTable lang={lang} />
        <UserReviews />
      </div>
      <Footer />
    </>
  );
};

export default Home;
