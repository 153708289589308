export const apiUrl = process.env.API_URL;
export const paymentezAppCode = process.env.PAYMENTEZ_APP_CODE;
export const paymentezAppKey = process.env.PAYMENTEZ_APP_KEY;
export const googleApiKey = process.env.GOOGLE_API_KEY;
export const honeybadgerConfig = {
  apiKey: process.env.HONEYBADGER_API_KEY,
  revision: process.env.HONEYBADGER_REVISION,
  environment: process.env.NODE_ENV,
};
export const environment = process.env.NODE_ENV;
