// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".F9wztOA2JZD0gDSEEEwy{width:100%;height:100%;position:fixed;top:0;right:0;bottom:0;left:0;display:flex;align-items:center;justify-content:center}.bhnbJh2yg8q0EplxAD3o{width:48px;height:auto;animation-name:Zo4rLg_jh8fwtaGQggKb;animation-duration:1s;animation-direction:alternate;animation-iteration-count:infinite;animation-timing-function:linear}@keyframes Zo4rLg_jh8fwtaGQggKb{from{transform:scale(1)}to{transform:scale(1.2)}}", "",{"version":3,"sources":["webpack://./src/components/Loading/loading.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,MAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CAEA,mCAAA,CACA,qBAAA,CACA,6BAAA,CACA,kCAAA,CACA,gCAAA,CAGF,gCACE,KACE,kBAAA,CAEF,GACE,oBAAA,CAAA","sourcesContent":[".wrapper {\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.logo {\n  width: 48px;\n  height: auto;\n\n  animation-name: grow;\n  animation-duration: 1s;\n  animation-direction: alternate;\n  animation-iteration-count: infinite;\n  animation-timing-function: linear;\n}\n\n@keyframes grow {\n  from {\n    transform: scale(1);\n  }\n  to {\n    transform: scale(1.2);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "F9wztOA2JZD0gDSEEEwy",
	"logo": "bhnbJh2yg8q0EplxAD3o",
	"grow": "Zo4rLg_jh8fwtaGQggKb"
};
export default ___CSS_LOADER_EXPORT___;
