import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';
import useStore from 'store/store';
import routes from '../routeList';

const PrivateRoute = (props) => {
  const state = useStore((state) => state);
  const customer = state.customer;
  const isCustomerLoading = state.isCustomerLoading;
  const { component: Component, path, ...rest } = props;

  if (isCustomerLoading) return <Loading />;

  if (customer) {
    return <Route path={path} {...rest} render={(props) => <Component {...props} />} />;
  }

  return <Redirect to={routes.home} />;
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired, // lazy components are objects, "normal" components are functions
};

export default PrivateRoute;
