export default {
  title: {
    en: `So real you
    won't believe it.`,
    es: `Tan real que
    no lo creerás`,
  },
  body: {
    es: `Nuestras barras de proteína son producidas orgullosamente en Ecuador
    y hechas con ingredientes reales que provienen de nuestros valles
    andinos y haciendas cacaoteras.

    Con cada barra, creamos un producto sano, orgánico y delicioso para personas
    activas que buscan una fuente de energía necesaria para cumplir sus
    metas día a día.
    `,
    en: `Our protein bars are proudly made in Ecuador with real ingredients sourced
    from the Andean valleys and cocoa farms.

    With each bar, we create a healthy, organic and delicious product for active
    people looking for a source of energy needed to meet their daily goals.`,
  },
  cta: {
    en: 'Buy Now',
    es: 'Comprar',
  },
  serving: {
    en: 'Serving',
    es: 'Porción',
  },
  energy: {
    en: 'Energy',
    es: 'Energía',
  },
  protein: {
    en: 'Protein',
    es: 'Proteína',
  },
  ingredients: {
    en: 'Ingredients',
    es: 'Ingredientes',
  },
};
