import callApi from './helpers/callApi';

export const addToCart = (data) => {
  const options = {
    method: 'POST',
    url: '/cart/products',
    data,
  };

  return callApi(options);
};

export const getCurrentCart = () => {
  const options = {
    method: 'GET',
    url: '/cart',
  };

  return callApi(options);
};

export const updateCart = (productId, variantId, data) => {
  const options = {
    method: 'PUT',
    url: `/cart/products/${productId}?variantId=${variantId}`,
    data,
  };

  return callApi(options);
};

export const deleteFromCart = (productId, variantId) => {
  const options = {
    method: 'DELETE',
    url: `/cart/products/${productId}?variantId=${variantId}`,
  };

  return callApi(options);
};
