export default {
  header: {
    en: 'Protein Bars made with real ingredients',
    es: 'Barras de proteína hechas con ingredientes reales',
  },
  cta: {
    en: 'Buy Now',
    es: 'Comprar',
  },
};
