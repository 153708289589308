import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'components/Swiper';

import content from './content';
import styles from './userReviews.module.scss';

const Review = ({ name, occupation, text, image }) => (
  <div className={styles.testimonialContainer}>
    <div className={styles.testimonialContent}>
      <p className={styles.testimonialText}>{`"${text}".`}</p>
      <p className={styles.testimonialAuthor}>
        - {name}, {occupation}
      </p>
    </div>
    <div className={styles.testimonialPhotoContainer}>
      <img className={styles.testimonialPhoto} src={image}></img>
    </div>
  </div>
);

Review.propTypes = {
  name: PropTypes.string.isRequired,
  occupation: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

const UserReviews = () => {
  const slides = content.map((testimonial, i) => (
    <Review key={`${testimonial.name}_${i}`} {...testimonial} />
  ));

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Swiper slides={slides} />
      </div>
    </div>
  );
};

export default UserReviews;
