export default {
  login: {
    placeholder1: {
      es: 'Correo',
      en: 'Email',
    },
    placeholder2: {
      es: 'Contraseña',
      en: 'Password',
    },
    cta: {
      es: 'Acceder',
      en: 'Log In',
    },
    span1: {
      es: '¿Olvidaste tu contraseña?',
      en: 'Forgot your password?',
    },
    span2: {
      es: '¿No tienes cuenta?',
      en: `Don't have an account?`,
    },
    link: {
      es: 'Regístrate',
      en: 'Sign up',
    },
  },
  register: {
    placeholder1: {
      es: 'Nombre Completo',
      en: 'Full Name',
    },
    placeholder2: {
      es: 'Correo',
      en: 'Email',
    },
    placeholder3: {
      es: 'Contraseña (min. 6 caracteres)',
      en: 'Password (min. 6 characters)',
    },
    placeholder4: {
      es: 'Fecha de Nacimiento (dd/mm/aaaa)',
      en: 'Date of Birth (dd/mm/aaaa)',
    },
    cta: {
      es: 'Crear Cuenta',
      en: 'Create Account',
    },
    span1: {
      es: 'Al crear una cuenta aceptas nuestros',
      en: 'By creating an account you agree to our',
    },
    link1: {
      es: 'Términos y Condiciones',
      en: 'Terms and Conditions',
    },
    span2: {
      es: '¿Ya tienes una cuenta?',
      en: `Have an account?`,
    },
    link2: {
      es: 'Inicia Sesión',
      en: 'Log In',
    },
  },
};
