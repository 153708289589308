import React from 'react';
import logo from '../../../assets/logos/logo_word.svg';
import styles from '../footer.module.scss';

const BrandInfo = () => {
  return (
    <div className={styles.socialInfoWrapper}>
      <div className={styles.socialInfoContainer}>
        <div className={styles.logos}>
          <img className={styles.logoWord} src={logo} alt="Kandu Logo Word" />
          <p className={styles.logoPhrase}>Real food, real people</p>
        </div>
      </div>
    </div>
  );
};

export default BrandInfo;
