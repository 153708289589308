import { useState } from 'react';
import { addToCart, getCurrentCart, updateCart, deleteFromCart } from 'api/cart';
import useStore from 'store/store';

export default () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const setCart = useStore((state) => state.setCart);

  // Helpers
  const handleStart = () => {
    setIsLoading(true);
    setError(null);
  };

  const handleError = (error) => {
    setIsLoading(false);
    setError(error.response?.data);
  };

  const initializeCart = async () => {
    try {
      handleStart();
      const response = await getCurrentCart();
      await setCart(response);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const addProductToCart = async (data, cb = () => {}) => {
    try {
      handleStart();
      const response = await addToCart(data);
      await setCart(response);
      cb();
      setIsLoading(false);
    } catch (error) {
      cb(error);
      handleError(error);
    }
  };

  const changeCartQuantity = async (productId, variantId, data) => {
    try {
      handleStart();
      const response = await updateCart(productId, variantId, data);
      await setCart(response);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  const removeProduct = async (productId, variantId) => {
    try {
      handleStart();
      const response = await deleteFromCart(productId, variantId);
      setCart(response);
      setIsLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  return {
    removeProduct,
    changeCartQuantity,
    initializeCart,
    addProductToCart,
    isLoading,
    error,
  };
};
