import routes from 'router/routeList';
import links from 'links';

export default {
  publicLinks: [
    {
      name: {
        es: 'Productos',
        en: 'Products',
      },
      protein: `/productos/${links.peanutButterSlug}`,
      energy: `/productos/${links.chocoBananaSlug}`,
      granola: `/productos/${links.traditionalGranolaSlug}`,
      shirt: `/productos/${links.defaultShirtSlug}`,
    },
    {
      name: {
        es: 'Encuéntranos',
        en: 'Store Locator',
      },
      to: routes.storeLocator,
    },
    {
      name: {
        es: 'Nosotros',
        en: 'Our Story',
      },
      to: routes.about,
    },
  ],
};
