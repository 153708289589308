import React, { useState } from 'react';
import PopUp from 'components/PopUp';
import useStore from 'store/store';
import LoginForm from 'components/PopUp/components/LoginForm';
import RegisterForm from 'components/PopUp/components/RegisterForm';
import PropTypes from 'prop-types';

const AuthModal = ({ shouldRedirect, showRegister = true, showModal, setShowModal }) => {
  const lang = useStore((state) => state.lang);
  const [renderRegister, setRenderRegister] = useState(showRegister);

  const registerTitle = lang === 'es' ? 'Registro' : 'Register';
  const loginTitle = lang === 'es' ? 'Iniciar sesión' : 'Login';

  return (
    <PopUp
      showModal={showModal}
      setShowModal={setShowModal}
      title={renderRegister ? registerTitle : loginTitle}
      size="medium"
      setRenderRegister={setRenderRegister}
      renderRegister={renderRegister}
    >
      {renderRegister ? (
        <RegisterForm
          setRenderRegister={setRenderRegister}
          setShowModal={setShowModal}
          shouldRedirect={shouldRedirect}
        />
      ) : (
        <LoginForm
          setRenderRegister={setRenderRegister}
          shouldRedirect={shouldRedirect}
          setShowModal={setShowModal}
        />
      )}
    </PopUp>
  );
};

AuthModal.propTypes = {
  shouldRedirect: PropTypes.bool,
  showRegister: PropTypes.bool,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default AuthModal;
