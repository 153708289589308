import React from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './swiper.scss';

const SwiperWrapper = ({ slides, onSlideChange = () => {} }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={30}
      slidesPerView={1}
      loop={true}
      navigation
      pagination={{ clickable: true }}
      onSlideChange={onSlideChange}
    >
      {slides.map((slide, i) => (
        <SwiperSlide key={`slide_${i}`}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
};

SwiperWrapper.propTypes = {
  slides: PropTypes.array.isRequired,
  onSlideChange: PropTypes.func,
};

export default SwiperWrapper;
