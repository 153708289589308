import React from 'react';
import instagramIcon from '../../../assets/logos/instagram.svg';
import facebookIcon from '../../../assets/logos/facebook.svg';

import styles from '../footer.module.scss';

const BottomSection = () => {
  return (
    <div className={styles.bottomSection}>
      <div className={styles.copyrightContainer}>
        <span>© Kandú {new Date().getFullYear()}</span>
      </div>
      <div className={styles.twellasContainer}>
        Made with ♥ by{' '}
        <a className={styles.twellas} href="https://www.twellas.com">
          Twellas
        </a>
      </div>
      <div className={styles.iconsContainer}>
        <a href="https://www.instagram.com/kandu.ec" className={styles.iconsContainer}>
          <img src={instagramIcon} alt="instagram logo" />
        </a>
        <a href="https://www.facebook.com/kandu.ec/" className={styles.iconsContainer}>
          <img src={facebookIcon} alt="facebook logo" />
        </a>
      </div>
    </div>
  );
};

// facebook, instragam, twitter

export default BottomSection;
