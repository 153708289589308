import React from 'react';
import PropTypes from 'prop-types';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { honeybadgerConfig } from 'config';
import ErrorPage from 'components/ErrorPage';

const honeybadger = Honeybadger.configure(honeybadgerConfig);

const ErrorBoundary = ({ children }) => (
  <HoneybadgerErrorBoundary honeybadger={honeybadger} ErrorComponent={ErrorPage}>
    {children}
  </HoneybadgerErrorBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
