import React from 'react';
import PropTypes from 'prop-types';
import workLogo from '../../../../assets/logos/Work.svg';
import adventureLogo from '../../../../assets/logos/Adventura.svg';
import snackLogo from '../../../../assets/logos/Snack.svg';
import workoutLogo from '../../../../assets/logos/Workout.svg';
import content from './content.js';
import links from 'links';
import styles from '../../home.module.scss';

const WhenToEat = ({ lang }) => {
  return (
    <div className={styles.whenToEatWrapper}>
      <div className={styles.whenToEatContent}>
        <div className={styles.whenToEatPhoto}>
          <img src={links.whenToEatPhoto} style={{ height: '100%' }} />
        </div>
        <div className={styles.whenToEatInfo}>
          <h2 className={styles.title}>{content.header[lang]}</h2>
          <div className={styles.chartContainer}>
            <div className={`${styles.chartSquare} ${styles.topLeftSquare}`}>
              {content.work.title[lang]}
              <span className={styles.span}>{content.work.description[lang]}</span>
              <div className={styles.iconContainer}>
                <img className={styles.icon} src={workLogo} />
              </div>
            </div>
            <div className={`${styles.chartSquare} ${styles.topRightSquare}`}>
              {content.adventure.title[lang]}
              <span className={styles.span}>{content.adventure.description[lang]}</span>
              <div className={styles.iconContainer}>
                <img className={styles.icon} src={adventureLogo} />
              </div>
            </div>
            <div className={`${styles.chartSquare} ${styles.bottomLeftSquare}`}>
              {content.workout.title[lang]}
              <span className={styles.span}>{content.workout.description[lang]}</span>
              <div className={styles.iconContainer}>
                <img className={styles.icon} src={workoutLogo} />
              </div>
            </div>
            <div className={`${styles.chartSquare} ${styles.bottomRightSquare}`}>
              {content.snack.title[lang]}
              <span className={styles.span}>{content.snack.description[lang]}</span>
              <div className={styles.iconContainer}>
                <img className={styles.icon} src={snackLogo} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WhenToEat.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default WhenToEat;
