import React from 'react';
import useStore from 'store/store';
import { AiOutlineShoppingCart as EmptyCartIcon } from '@react-icons/all-files/ai/AiOutlineShoppingCart';

import styles from '../cart.module.scss';

const EmptyCart = () => {
  const lang = useStore((state) => state.lang);
  return (
    <div className={`${styles.content} ${styles.emptyCart}`}>
      <EmptyCartIcon className={styles.emptyCartIcon} />
      <p className={styles.emptyTitle}>
        {lang === 'es' ? 'Tu carrito está vacío' : 'Your cart is empty'}
      </p>
    </div>
  );
};

export default EmptyCart;
