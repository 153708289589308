import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './styles/app.scss'; // Load app styles
import './api'; // Load axios
import { setEnvironmentVariables } from 'utils/setup';

setEnvironmentVariables();

const root = document.getElementById('root');
ReactDOM.render(<App />, root);
