import React from 'react';
import PropTypes from 'prop-types';
import links from 'links';
import styles from '../../home.module.scss';

const BarAnatomy = ({ lang }) => {
  const title =
    lang === 'es'
      ? `Kandú enriquece el cuerpo y la mente. Nuestras barritas son preferidas por deportistas de
    alto nivel, profesionales, estudiantes universitarios y viajeros en largos recorridos.`
      : `Kandú nourishes body and mind. Our bars are appreciated by top athletes, busy
    professionals, university students and travelers on long journeys.`;

  return (
    <div className={styles.barAnatomyWrapper}>
      <div className={styles.barAnatomyContent}>
        <h1 className={`${styles.homePageTitle} ${styles.capsTitle}`}>{title}</h1>
        <div className={styles.barAnatomyPhotoContainer}>
          <img src={links.barAnatomyPhoto} />
        </div>
      </div>
    </div>
  );
};

BarAnatomy.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default BarAnatomy;
