import routes from 'router/routeList';
import links from 'links';

export default [
  {
    title: {
      es: 'Productos',
      en: 'Products',
    },
    links: [
      {
        text: {
          es: 'Barras de Proteína',
          en: 'Protein Bars',
        },
        to: `/productos/${links.peanutButterSlug}`,
      },
      {
        text: {
          es: 'Barras de Energía',
          en: 'Energy Bars',
        },
        to: `/productos/${links.chocoBananaSlug}`,
      },
      {
        text: {
          es: 'Granolas',
          en: 'Granolas',
        },
        to: `/productos/${links.traditionalGranolaSlug}`,
      },
      {
        text: {
          es: 'T-Shirts',
          en: 'T-Shirts',
        },
        to: `/productos/${links.defaultShirtSlug}`,
      },
    ],
  },
  {
    title: {
      es: 'Conócenos',
      en: 'This is Us',
    },
    links: [
      {
        text: {
          es: 'Nuestra Historia',
          en: 'Our Story',
        },
        to: routes.about,
      },
      {
        text: {
          es: 'Encuéntranos',
          en: 'Store Locator',
        },
        to: routes.storeLocator,
      },
    ],
  },
  {
    title: {
      es: 'Ayuda',
      en: 'Help',
    },
    links: [
      {
        text: {
          es: 'Contacto',
          en: 'Contact',
        },
        to: routes.contact,
      },
      {
        text: {
          es: 'Términos y Condiciones',
          en: 'Terms and Conditions',
        },
        to: routes.terms,
      },
    ],
  },
];
