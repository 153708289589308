import React from 'react';
import PropTypes from 'prop-types';
import content from './content.js';
import links from 'links';
import { Link } from 'react-router-dom';

import styles from './meetOurBars.module.scss';

const MeetOurBars = ({ lang }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>{content.title[lang]}</h1>
        <p>{content.body[lang]}</p>
        <Link to={`/productos/${links.peanutButterSlug}`}>
          <p className={styles.action}>{content.cta[lang]}</p>
        </Link>
      </div>
    </div>
  );
};

MeetOurBars.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default MeetOurBars;
