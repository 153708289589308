import React from 'react';
import PropTypes from 'prop-types';
import styles from './hashtags.module.scss';

const Hashtags = ({ text }) => {
  const n = 10;

  const words = Array(n).fill(text);
  const words2 = Array(n).fill(text);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.words}>
          {words.map((word, i) => (
            <span className={styles.word} key={`${word}_${i}_1`}>
              {word}
            </span>
          ))}
        </div>
        <div className={styles.words}>
          {words2.map((word, j) => (
            <span className={styles.word} key={`${word}_${j}_2`}>
              {word}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

Hashtags.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Hashtags;
