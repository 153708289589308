export const setEnvironmentVariables = () => {
  if (process.env.NODE_ENV === 'production') {
    window.paymentezEnvMode = 'prod';
  } else {
    window.paymentezEnvMode = 'stg';
  }

  window.paymentezAppKey = process.env.PAYMENTEZ_APP_KEY;
  window.paymentezAppCode = process.env.PAYMENTEZ_APP_CODE;
};
