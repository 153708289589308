export default {
  chocolateChipsSlug: 'chocolate',
  chocolateChipsPhoto:
    'https://kandu.nyc3.cdn.digitaloceanspaces.com/products/chocolate.png',
  peanutButterSlug: 'peanut-butter',
  peanutButterPhoto: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/products/peanut.png',
  cranberrySlug: 'cranberry',
  cranberryPhoto: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/products/cranberry.png',
  chocoBananaSlug: 'choco-banana',
  chocoBananaPhoto:
    'https://kandu.nyc3.cdn.digitaloceanspaces.com/products/chocobanano.png',
  chocoGranolaSlug: 'choco-granola',
  chocoGranolaPhoto: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/products/granola.png',
  traditionalGranolaSlug: 'granola-tradicional',
  defaultShirtSlug: 'betheenergy-tshirt',
  whenToEatPhoto: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/static/bars_1.png',
  peanutsTop: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/static/peanuts_top.png',
  peanutsBottom:
    'https://kandu.nyc3.cdn.digitaloceanspaces.com/static/peanuts_bottom.png',
  fourGridPhoto: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/static/grid.png',
  barAnatomyPhoto:
    'https://kandu.nyc3.cdn.digitaloceanspaces.com/static/bar_description.png',
  contactBarsPhoto: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/static/bars_2.png',
  foundersPhoto: 'https://kandu.nyc3.cdn.digitaloceanspaces.com/static/founders.png',
};
