import { getCustomer } from 'api/customer';
import useStore from 'store/store';

const useUser = () => {
  const state = useStore((state) => state);
  const isCustomerLoading = state.isCustomerLoading;

  const initializeUser = async () => {
    try {
      const user = await getCustomer();
      state.setCustomer(user);
      state.setIsCustomerLoading(false);
    } catch (error) {
      state.setCustomer(null);
      state.setIsCustomerLoading(false);
    }
  };

  return { initializeUser, isCustomerLoading };
};

export default useUser;
