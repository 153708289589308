export default {
  title: {
    en: 'Oops! Something went wrong.',
    es: '¡Oops! Algo salió mal.',
  },
  description: {
    en: 'An error has occurred. Please try again later or contact support.',
    es: 'Lo sentimos, pero ha ocurrido un error. Por favor, inténtalo nuevamente más tarde o contacta a soporte.',
  },
};
