import React from 'react';
import BrandInfo from './components/BrandInfo';
import SiteLinks from './components/SiteLinks';
import BottomSection from './components/BottomSection';
import styles from './footer.module.scss';

const Footer = () => (
  <footer className={styles.wrapper}>
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.topSection}>
          <BrandInfo />
          <SiteLinks />
        </div>
        <BottomSection />
      </div>
    </div>
  </footer>
);

export default Footer;
