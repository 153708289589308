import React from 'react';
import useCart from 'hooks/useCart';
import useCheckout from 'hooks/useCheckout';
import useStore from 'store/store';
import CallToAction from 'components/CallToAction';
import EmptyCart from './EmptyCart';
import styles from './cart.module.scss';
import { RiCloseLine as CloseIcon } from '@react-icons/all-files/ri/RiCloseLine';
import { toast } from 'react-hot-toast';

const Cart = () => {
  const cart = useStore((state) => state.cart);
  const store = useStore((state) => state);
  const { redirectToCheckout, isLoading } = useCheckout(true);
  const { changeCartQuantity, removeProduct } = useCart();

  const changeQuantity = (e, product) => {
    changeCartQuantity(product._id, product.variantId, { quantity: e.target.value });
  };

  const removeFromCart = (product) => {
    removeProduct(product._id, product.variantId);
  };

  const handleSubmit = () => {
    redirectToCheckout((error) => {
      toast.error(error);
    });
  };

  return (
    <div className={`${styles.wrapper} ${store.isCartOpen ? styles.open : ''}`}>
      <div
        onClick={() => {
          store.setIsCartOpen(store.isCartOpen);
        }}
        className={styles.overlay}
      ></div>
      <div
        className={`${styles.container} ${
          store.isCartOpen ? styles.openCart : styles.closeCart
        }`}
      >
        <div className={styles.cartHeader}>
          {cart?.productCount ? (
            <h1 className={styles.cartTitle}>{`${
              store.lang === 'es' ? 'Carrito' : 'Cart'
            }`}</h1>
          ) : (
            <h1></h1>
          )}

          <CloseIcon
            className={styles.icon}
            onClick={() => {
              store.setIsCartOpen(store.isCartOpen);
            }}
          />
        </div>
        {!cart || cart.products.length === 0 ? (
          <EmptyCart />
        ) : (
          <div className={styles.content}>
            <div className={styles.productsWrapper}>
              {cart.products.map((p, i) => {
                return (
                  <div key={i} className={styles.productContainer}>
                    <div
                      className={styles.imageWrapper}
                      style={{ backgroundColor: p.hexCode }}
                    >
                      <div className={styles.imageContainer}>
                        <img src={p.image} className={styles.productImage}></img>
                      </div>
                    </div>
                    <div className={styles.productInfoContainer}>
                      <div className={styles.spaceContent}>
                        <h2>{p.name}</h2>
                        <span>${(Math.round(p.amount * 100) / 100).toFixed(2)}</span>
                      </div>
                      <span>{p?.variantName}</span>
                      <div className={styles.spaceContent}>
                        <input
                          value={p.quantity}
                          placeholder="1"
                          type="number"
                          id="quantity"
                          name="quantity"
                          min="1"
                          max={p.stock}
                          onChange={(e) => {
                            changeQuantity(e, p);
                          }}
                        ></input>
                        <span
                          onClick={() => {
                            removeFromCart(p);
                          }}
                          className={styles.deleteButton}
                        >
                          {store.lang === 'es' ? 'Eliminar' : 'Delete'}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={styles.totalContainer}>
              <span className={styles.divider}></span>
              <div className={styles.spaceContent}>
                <h2>Total</h2>
                <h2>${(Math.round(cart.total * 100) / 100).toFixed(2)}</h2>
              </div>
              <CallToAction
                text="Checkout"
                onClick={handleSubmit}
                isLoading={isLoading}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cart;
