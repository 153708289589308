import React from 'react';
import styles from './productsDisplay.module.scss';
import links from 'links';
import { Link } from 'react-router-dom';

const ProductsDisplay = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.upperSection}>
        <div className={styles.section}>
          <Link to={`/productos/${links.chocolateChipsSlug}`}>
            <img src={links.chocolateChipsPhoto} className={styles.picture} />
          </Link>
        </div>
        <div className={styles.section}>
          <Link to={`/productos/${links.chocoGranolaSlug}`}>
            <img src={links.chocoGranolaPhoto} className={styles.picture} />
          </Link>
        </div>
      </div>
      <div className={styles.lowerSection}>
        <div className={styles.section}>
          <Link to={`/productos/${links.chocoBananaSlug}`}>
            <img src={links.chocoBananaPhoto} className={styles.picture} />
          </Link>
        </div>
        <div className={styles.section}>
          <Link to={`/productos/${links.cranberrySlug}`}>
            <img src={links.cranberryPhoto} className={styles.picture} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductsDisplay;
