import React from 'react';
import PropTypes from 'prop-types';
import {
  addDateSeparator,
  isValidDateChar,
  addCreditCardSeparator,
  spaceCardNumber,
  isValidCardChar,
} from 'utils/dates';
import styles from './dateInput.module.scss';

const DateInput = ({
  type,
  name,
  inputMode,
  pattern,
  placeholder,
  autoComplete,
  maxLength,
  value,
  onChange,
  formatType,
}) => {
  const handleChange = (event) => {
    const { target } = event;

    if (formatType === 'cardNumber') {
      if (isValidCardChar(target.value)) {
        const formattedValue = spaceCardNumber(target.value, value);
        event.target.value = formattedValue;
        return onChange(event);
      }
    }

    if (isValidDateChar(target.value)) {
      if (formatType === 'cardExp') {
        const formattedValue = addCreditCardSeparator(target.value, value);
        event.target.value = formattedValue;
        return onChange(event);
      }
      if (formatType === 'birthDate') {
        const formattedValue = addDateSeparator(target.value, value);
        event.target.value = formattedValue;
        return onChange(event);
      }
    }
  };

  return (
    <input
      type={type}
      name={name}
      inputMode={inputMode}
      pattern={pattern}
      maxLength={maxLength}
      autoComplete={autoComplete}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      className={styles.input}
    ></input>
  );
};

DateInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputMode: PropTypes.string.isRequired,
  pattern: PropTypes.string.isRequired,
  maxLength: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formatType: PropTypes.string.isRequired,
};

export default DateInput;
