import React from 'react';
import logo from '../../assets/logos/logo_word.svg';
import useStore from 'store';
import content from './content';
import styles from './error-page.module.scss';

const ErrorPage = () => {
  const { lang } = useStore((state) => state);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={logo} alt="logo" className={styles.logo} />
      </div>
      <div className={styles.container}>
        <h1 className={styles.title}>{content.title[lang]}</h1>
        <p className={styles.description}>{content.description[lang]}</p>
      </div>
    </div>
  );
};

export default ErrorPage;
