import callApi from './helpers/callApi';

export const login = (data) => {
  const options = {
    method: 'POST',
    url: '/auth/login',
    data,
  };

  return callApi(options);
};

export const logout = () => {
  const options = {
    method: 'POST',
    url: '/auth/logout',
  };

  return callApi(options);
};

export const register = (data) => {
  const options = {
    method: 'POST',
    url: '/auth/registration',
    data,
  };

  return callApi(options);
};

export const requestPasswordChange = (data) => {
  const options = {
    method: 'POST',
    url: '/auth/password-recovery',
    data,
  };

  return callApi(options);
};

export const changePassword = (data) => {
  const options = {
    method: 'POST',
    url: '/auth/new-password',
    data,
  };

  return callApi(options);
};
